import Vue from "vue";
import VueNativeSock from 'vue-native-websocket';
import { LOCAL_MODE, LOCAL_IP } from "@/store/constants";

export function initializeWebSocket(store) {
  if (process.env.VUE_APP_REMOTE_MODE == 'false') {
    Vue.use(
      VueNativeSock,
      LOCAL_MODE ? LOCAL_IP : "ws://" + location.hostname + ":8887",
      {
        store,
        format: 'json',
        reconnection: true,
        reconnectionAttempts: 100,
        reconnectionDelay: 3000,
      }
    );
  }
}
