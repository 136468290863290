<template>
  <div class="automation">
    <app-navigation :title="$t('scene.scenes')" actionButtonIcon="fa fa-plus"
      :actionButtonDesc="$t('scene.add-new-scene')" @action="onClickAddScene"></app-navigation>


    <el-table :data="$store.getters.scenes" height="500" v-loading="loading">

      <el-table-column type="expand">
        <template slot-scope="groups">
          <el-table :data="groups.row.actionSet">

            <el-table-column :label="$t('scene.delay')">
              <template slot-scope="scope">{{ delayString(scope.row) }}</template>
            </el-table-column>

            <el-table-column :label="$t('scene.action-count')">

              <template slot-scope="scope"> {{ $tc('scene.how-many-action', scope.row.actionList.length) }} </template>

            </el-table-column>

            <el-table-column :label="$t('scene.actions')" width="550">
              <template slot-scope="scope">
                <div v-for="(action, index) in scope.row.actionList" :key="index">
                  {{ commandDescription(action) }}
                </div>
              </template>
            </el-table-column>

          </el-table>
        </template>
      </el-table-column>

      <el-table-column prop="icon" :label="$t('scene.icon')" width="100">

        <template slot-scope="scope">
          <img @error="onImageError" :src="'/assets/icons/' + scope.row.icon + '.png'" class="icon-contact">
        </template>

      </el-table-column>

      <el-table-column prop="name" :label="$t('scene.name')" sortable>

      </el-table-column>

      <el-table-column :label="$t('scene.room')" prop="roomId" :filters="roomList" :filter-method="filterRoom">
        <template slot-scope="scope">{{ roomName(scope.row.roomId) }}</template>
      </el-table-column>

      <el-table-column prop="favorite" :label="$t('scene.favorite')" width="120">

        <template slot-scope="scope">
          <div class="favorite-icon" style="width: 100%; text-align: center">
            <i :class="scope.row.favorite ? 'fa fa-star' : 'far fa-star'"
              :style="{ color: scope.row.favorite ? '#FFC107' : '#9e9e9e' }" @click="switchFavorite(scope.row)"></i>
          </div>
        </template>

      </el-table-column>

      <el-table-column align="right" :label="$t('scene.actions')">

        <template slot-scope="scope">
          <span class="icon" @click="onClickEditScene(scope.row)"><i class="far fa-edit"></i></span>
          <span class="icon" @click="removeScene(scope.row.id)"><i class="far fa-trash-alt"></i></span>
        </template>

      </el-table-column>

    </el-table>

  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import { initializeWebSocket } from "@/utils/socket-utils"
import { className, getActionArgumentValueString, getActionServiceString } from "@/utils/accessory-utils";

export default {
  name: "Scenes",
  components: {
    'app-navigation': Navigation
  },

  data: function () {
    return {
      loading: false,
    }
  },

  computed: {
    roomList: function () {
      return this.$store.getters.rooms.map(room => {
        return { text: room.name, value: room.id }
      })
    },
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    className,
    getActionArgumentValueString,
    getActionServiceString,

    filterRoom(value, row) {
      console.log(value, row)
      return row.roomId == value
    },

    commandDescription(action) {
      console.log(action)

      let description = this.$store.getters.accessories.find(accessory => accessory.id == action.accessory.properties.id).name + " (" + this.getActionServiceString(action.service) + ") : "

      if (action.argumentList) {
        for (let [key, value] of Object.entries(action.argumentList)) {
          description += (key == "newTargetValue" ? this.$t('scene.value') : this.$t('scene.mode')) + " -> " + getActionArgumentValueString(value) + ", "
        }
      }

      return description
    },

    delayString: function (actionGroup) {
      let second = actionGroup.delay % 60
      let minute = ((actionGroup.delay - second) % (60 * 60)) / 60
      let hour = parseInt(actionGroup.delay / (60 * 60))

      if (hour <= 0 && minute <= 0 && second <= 0)
        return this.$t('scene.immediate');
      return this.$tc('scene.hour-p', hour) + " " + this.$tc('scene.min-p', minute) + " " + this.$tc('scene.sec-p', second) + " " + this.$t('scene.delayed');
      //return (hour > 0 ? hour + " hour " : "") + (minute > 0 ? minute + " min " : "") + (second > 0 ? second + " sec " : "") + "delayed";
    },

    roomName(roomId) {
      const room = this.$store.getters.rooms.find(room => room.id == roomId)
      return room ? room.name : roomId
    },

    onImageError: function (e) {
      e.target.src = "/assets/icons/ic_scene_default.png";
    },

    switchFavorite(scene) {
      // scene.actionSet.forEach(actionGroup => {
      //
      //   actionGroup.actionList = actionGroup.actionList.map(action => {
      //     return {
      //       accessory: {
      //         type: this.className(action.accessory.category),
      //         properties: action.accessory
      //       },
      //       service: action.service,
      //       argumentList: action.argumentList
      //     }
      //   })
      // })

      console.log("switchfavorite", scene)
      scene.favorite = !scene.favorite
      this.$store.dispatch("editScene", scene)
    },

    onClickAddScene: function () {
      this.$store.commit("resetSceneFormData")
      this.$router.push({ name: 'SceneAdd' })
    },

    onClickEditScene: function (scene) {
      this.$store.commit("resetSceneFormData")
      this.$store.commit("setSceneEditMode", true)
      this.$store.commit("setSceneData", { ...scene })
      this.$router.push({ name: 'SceneEdit' })
    },

    removeScene: function (sceneId) {
      this.$confirm(this.$t('alert.message.confirm-delete'), this.$t('alert.title.warning'), {
        confirmButtonText: this.$t('alert.button.ok'),
        cancelButtonText: this.$t('alert.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('removeScene', sceneId).then(response => {
          this.$message({
            message: this.$t(response.message),
            type: 'success'
          })
        }).catch(reason => {
          this.$message({
            message: this.$t(reason),
            type: 'error'
          })
        })
      })
    },
    async fetchData() {
      try {
        await this.$store.dispatch("getScenes")
        await this.$store.dispatch("getAccessories")
        await this.$store.dispatch("getRooms")
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  },

  created() {
    if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
  }
}
</script>

<style scoped>
@import '../../../style/automation.css';
</style>